import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { useParams, withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';

import DataTable from 'react-data-table-component';
import Input from 'react-phone-number-input/input';

import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const Cadastro = () => {
  const { id } = useParams();
  
  const { addToast } = useToasts();
  const [ cadastros, setCadastros ] = useState([]);
  const [ cadastrosEndereco, setCadastrosEndereco ] = useState([]);
  const [ formCadastro, setFormCadastro ] = useState([]);
  const [ formCadastroEndereco, setFormCadastroEndereco ] = useState([]);
  const [ tipoPessoaSelect, setTipoPessoaSelect ] = useState([]);
  const [ tipoSocioSelect, setTipoSocioSelect ] = useState([]);
  const [ areaAtuacaoSelect, setAreaAtuacaoSelect ] = useState([]);
  const [ paisSelect, setPaisSelect ] = useState([]);
  const [ estadoSelect, setEstadoSelect ] = useState([]);
  const [ cidadeSelect, setCidadeSelect ] = useState([]);
  const [ regionalSelect, setRegionalSelect ] = useState([]);
  const [ modal, setModal ] = useState(false);
  const [ modalEndereco, setModalEndereco ] = useState(false);
  const [ buscaCodigo, setBuscaCodigo ] = useState();
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaFantasia, setBuscaFantasia ] = useState();
  const [ buscaTipoPessoa, setBuscaTipoPessoa ] = useState();
  const [ buscaTipoSocio, setBuscaTipoSocio ] = useState();
  const [ buscaAreaAtuacao, setBuscaAreaAtuacao ] = useState();
  const [ buscaTipoCadastro, setBuscaTipoCadastro ] = useState();
  const [ buscaPais, setBuscaPais ] = useState();
  const [ buscaEstado, setBuscaEstado ] = useState();
  const [ buscaCidade, setBuscaCidade ] = useState();
  const [ buscaRegional, setBuscaRegional ] = useState();
  const [ buscaTelefone, setBuscaTelefone ] = useState();
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ caProcessaEstado, setCaProcessaEstado ] = useState(false);
  const [ caProcessaCidade, setCaProcessaCidade ] = useState(false);
  const [ acao, setAcao ] = useState();

  const data = cadastros;
  const columns = [
    {
      name: <th>ID</th>,
      selector: 'id',
      width: '6%',
      sortable: true,
      center: true,
    },
    {
      name: <th>Nome</th>,
      bold: true,
      selector: 'dp_nome_rsociao',
      sortable: true,
      width: '28%',
    },
    {
      name: <th>Tipo Sócio</th>,
      selector: 'ts_nome',
      width: '12%',
      sortable: true,
    },
    {
      name: <th>Área de atuação</th>,
      selector: 'aa_nome',
      width: '12%',
      sortable: true,
     },
    {
      name: <th>Regional</th>,
      selector: 'regional',
      width: '10%',
      sortable: true,
      center: true,
      cell: row => { 
        return <>
            {row.regional === "Sul" && <>          
              <span className="label label-success label-inline mr-2">Sul</span>
            </>}
            {row.regional === "Leste" && <>          
              <span className="label label-info label-inline mr-2">Leste</span>
            </>}
            {row.regional === "Centro" && <>          
              <span className="label label-danger label-inline mr-2">Centro</span>
            </>}
            {row.regional === "Setentrional" && <>          
              <span className="label label-warning label-inline mr-2">Setentrional</span>
            </>}
          </>
        }
    },
    {
      name: <th>Tipo Cadastro</th>,
      selector: 'regional',
      width: '10%',
      sortable: true,
      center: true,
      cell: row => { 
        return <>
            {row.tipo_cadastro === "Cadastro" && <>          
              <span className="label label-info label-inline mr-2">Cadastro</span>
            </>}
            {row.tipo_cadastro === "Sócio" && <>          
              <span className="label label-success label-inline mr-2">Sócio</span>
            </>}
            {row.tipo_cadastro === "Desativado" && <>          
              <span className="label label-danger label-inline mr-2">Desativado</span>
            </>}
            {row.tipo_cadastro === "Inativo" && <>          
              <span className="label label-secondary label-inline mr-2">Inativo</span>
            </>}
          </>
        }
    },
    {
      name: <th>Ações</th>,
      selector: 'nome_campo',
      width: '22%',
      sortable: true,
      center: true,
      cell: (row) => 
      { return (<>
        <div class="btn-group">
          <button className="btn btn-info btn-sm dropdown-toggle mr-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Mais</button>
            <div class="dropdown-menu">
              <a class="dropdown-item">Anúncios</a>
              <Link to={"/sistema/anuidade"} class="dropdown-item" onClick={e => { sesaoCadastro(row.id) }}>Anuidades</Link>
              <a class="dropdown-item">Eventos</a>
            </div>
        </div>
        <button className="btn btn-warning btn-sm mr-2" onClick={e => { editar(row); sessionStorage.setItem('idCadastro', row.id) }}><i className="fas fa-pencil-alt" />Alterar</button>
        <button className="btn btn-danger btn-sm" onClick={e => { excluir(row) }}><i className="far fa-trash-alt" />Excluir</button>
      </>)
      }
    },
  ];

  useEffect(() => {
    getListar();
    getTipoPessoaSelect();
    getTipoSocioSelect();
    getAreaAtuacaoSelect();
    getPaisSelect(); 
    getRegionalSelect();
  }, []);

  useEffect(() => {
    if (formCadastroEndereco.pais)
      getEstadoSelect(formCadastroEndereco.pais);
  }, [formCadastroEndereco.pais]);

  useEffect(() => {
    if (formCadastroEndereco.uf)
      getCidadeSelect(formCadastroEndereco.uf);
  }, [formCadastroEndereco.uf]);


  function fecharModalEndereco(){
    setModalEndereco(false);
  }

  function sesaoCadastro($id) {
    sessionStorage.setItem('cadastro', $id);
    sessionStorage.setItem('voltar','cadastro');
  }

  function buscar(clicou) {
    if (sessionStorage.getItem('voltar') !== 'voltou' || clicou == 'Sim') {
      sessionStorage.setItem('cadastro_buscaCodigo', buscaCodigo !== undefined ? buscaCodigo : '');
      sessionStorage.setItem('cadastro_buscaNome', buscaNome !== undefined ? buscaNome : '');
      sessionStorage.setItem('cadastro_buscaFantasia', buscaFantasia !== undefined ? buscaFantasia : '');
      sessionStorage.setItem('cadastro_buscaTelefone', buscaTelefone !== undefined ? buscaTelefone : '');
      sessionStorage.setItem('cadastro_buscaTipoPessoa', buscaTipoPessoa !== undefined ? buscaTipoPessoa : '');
      sessionStorage.setItem('cadastro_buscaTipoSocio', buscaTipoSocio !== undefined ? buscaTipoSocio : '');
      sessionStorage.setItem('cadastro_buscaAreaAtuacao', buscaAreaAtuacao !== undefined ? buscaAreaAtuacao : '');
      sessionStorage.setItem('cadastro_buscaTipoCadastro', buscaTipoCadastro !== undefined ? buscaTipoCadastro : '');
      sessionStorage.setItem('cadastro_buscaPais', buscaPais !== undefined ? buscaPais : '');
      sessionStorage.setItem('cadastro_buscaEstado', buscaEstado !== undefined ? buscaEstado : '');
      sessionStorage.setItem('cadastro_buscaCidade', buscaCidade !== undefined ? buscaCidade : '');
      sessionStorage.setItem('cadastro_buscaRegional', buscaRegional !== undefined ? buscaRegional : '');
    } else {
      setBuscaCodigo(sessionStorage.getItem('cadastro_buscaCodigo'));
      setBuscaNome(sessionStorage.getItem('cadastro_buscaNome'));
      setBuscaFantasia(sessionStorage.getItem('cadastro_buscaFantasia'));
      setBuscaTelefone(sessionStorage.getItem('cadastro_buscaTelefone'));
      setBuscaTipoPessoa(sessionStorage.getItem('cadastro_buscaTipoPessoa'));
      setBuscaTipoSocio(sessionStorage.getItem('cadastro_buscaTipoSocio'));
      setBuscaAreaAtuacao(sessionStorage.getItem('cadastro_buscaAreaAtuacao'));
      setBuscaTipoCadastro(sessionStorage.getItem('cadastro_buscaTipoCadastro'));
      setBuscaPais(sessionStorage.getItem('cadastro_buscaPais'));
      setBuscaEstado(sessionStorage.getItem('cadastro_buscaEstado'));
      setBuscaCidade(sessionStorage.getItem('cadastro_buscaCidade'));
      setBuscaRegional(sessionStorage.getItem('cadastro_buscaRegional'));
    }
    getListar();
  }

  function getTipoPessoaSelect() {
    Api.get("tipopessoa/listar_a").then(rps => {
      var lis = rps.data.obj;
      var nLis = [];
      nLis[nLis.length] = {value: '', label: 'Selecione... Tipo pessoa'};
      lis.forEach(e => {
        nLis[nLis.length] = {value: e.id, label: e.nome};
      })
      setTipoPessoaSelect(nLis);
    })
  }

  function getTipoSocioSelect() {
    Api.get("tiposocio/listar_a").then(rps => {
      var lis = rps.data.obj;
      var nLis = [];
      nLis[nLis.length] = {value: '', label: 'Selecione... Tipo sócio'};
      lis.forEach(e => {
        nLis[nLis.length] = {value: e.id, label: e.nome};
      })
      setTipoSocioSelect(nLis);
    })
  }

  function getAreaAtuacaoSelect() {
    Api.get("areaatuacao/listar_a").then(rps => {
      var lis = rps.data.obj;
      var nLis = [];
      nLis[nLis.length] = {value: '', label: 'Selecione... Área atuação'};
      lis.forEach(e => {
        nLis[nLis.length] = {value: e.id, label: e.nome};
      })
      setAreaAtuacaoSelect(nLis);
    })
  }

  function getPaisSelect() {
    Api.get("pais/listar_a").then(rps => {
      var lis = rps.data.obj;
      var nLis = [];
      nLis[nLis.length] = {value: '', label: 'Selecione... País'};
      lis.forEach(e => {
        nLis[nLis.length] = {value: e.id, label: e.nome};
      })
      setPaisSelect(nLis);
    })
  }

  function getEstadoSelect(pais) {
    setCaProcessaEstado(true);
    var data = {
      'pais': pais
    }
    
    Api.post("estado/listarEstado", data).then(rps => {
      var cat = rps.data.obj;
      var nCat = [];
      nCat[nCat.length] = {value: '', label: 'Selecione...'};
      cat.forEach(e => {
        nCat[nCat.length] = {value: e.id, label: e.est_nome};
      })
      setEstadoSelect(nCat);
      setCaProcessaEstado(false);
    })
  }

  function getCidadeSelect(uf) {
    setCaProcessaCidade(true);
    var data = {
      'estado': uf
    }

    Api.post("cidade/listarCidade", data).then(rps => {
      var cat = rps.data.obj;
      var nCat = [];
      nCat[nCat.length] = {value: '', label: 'Selecione...'};
      cat.forEach(e => {
        nCat[nCat.length] = {value: e.nome, label: e.nome};
      })
      setCidadeSelect(nCat);
      setCaProcessaCidade(false);
    })
  }

  function getRegionalSelect() {
    Api.get("regional/listar").then(rps => {
      var res = rps.data.obj;
      var nRes = [];
      nRes[nRes.length] = {value: '', label: 'Selecione... Regional'};
      res.forEach(e => {
        nRes[nRes.length] = {value: e.regional, label: e.regional};
      })
      setRegionalSelect(nRes);
    })
  }

  function adicionar() {
    setFormCadastro({});
    setModal(true);
  }

  function editar(registro) {
    var reg = JSON.parse(JSON.stringify(registro));
    if (reg.dp_nasacimento !== null){
      reg.dp_nasacimento = new Date(reg.dp_nasacimento + ' 12:00:00');
    } 
    
    if (reg.dp_conclusao_curso !== null){
      reg.dp_conclusao_curso = new Date(reg.dp_conclusao_curso + ' 12:00:00');
    } 
    
    setFormCadastro(reg);
    getEnderecoCadastro(reg.id);
    setModal(true);
  }

  
  function getEnderecoCadastro(id) {
    setCaProcessa(true);

    var data = {
      id: id,
    }

    Api.post("cadastrosendereco/listar", data).then(rps => {    
      setCadastrosEndereco(rps.data.obj);
      setCaProcessa(false);
    });
  }

  function excluir(registro) {
    var ca = JSON.parse(JSON.stringify(registro));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('cadastros/excluir', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    })
  }

  function fecharModal(){
    setModal(false);
  }

  function salvar() {
    setCaProcessa(true);
    Api.post('cadastros/adicionar', formCadastro).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }
  
  function getListar() {
    setCaProcessa(true);
    
    if (sessionStorage.getItem('cadastro_buscaCodigo') == '') {
      var Rcodigo = '';
    } else { 
      var Rcodigo = sessionStorage.getItem('cadastro_buscaCodigo'); 
    }

    if (sessionStorage.getItem('cadastro_buscaNome') == '') {
      var Rnome = '';
    } else { 
      var Rnome = sessionStorage.getItem('cadastro_buscaNome'); 
    }

    if (sessionStorage.getItem('cadastro_buscaFantasia') == '') {
      var Rfantasia = '';
    } else { 
      var Rfantasia = sessionStorage.getItem('cadastro_buscaFantasia'); 
    }

    if (sessionStorage.getItem('cadastro_buscaTelefone') == '') {
      var Rtelefone = '';
    } else { 
      var Rtelefone = sessionStorage.getItem('cadastro_buscaTelefone'); 
    }
     
    if (sessionStorage.getItem('cadastro_buscaTipoPessoa') == '') {
      var RtipoPessoa = '';
    } else { 
      var RtipoPessoa = sessionStorage.getItem('cadastro_buscaTipoPessoa'); 
    }

    if (sessionStorage.getItem('cadastro_buscaTipoSocio') == '') {
      var RtipoSocio = '';
    } else { 
      var RtipoSocio = sessionStorage.getItem('cadastro_buscaTipoSocio'); 
    }

    if (sessionStorage.getItem('cadastro_buscaAreaAtuacao') == '') {
      var RareaAtuacao = '';
    } else { 
      var RareaAtuacao = sessionStorage.getItem('cadastro_buscaAreaAtuacao'); 
    }

    if (sessionStorage.getItem('cadastro_buscaTipoCadastro') == '') {
      var RtipoCadastro = '';
    } else { 
      var RtipoCadastro = sessionStorage.getItem('cadastro_buscaTipoCadastro'); 
    }

    if (sessionStorage.getItem('cadastro_buscaPais') == '') {
      var Rpais = '';
    } else { 
      var Rpais = sessionStorage.getItem('cadastro_buscaPais'); 
    }
      
    if (sessionStorage.getItem('cadastro_buscaEstado') == '') {
      var Restado = '';
    } else { 
      var Restado = sessionStorage.getItem('cadastro_buscaEstado'); 
    }
    
    if (sessionStorage.getItem('cadastro_buscaCidade') == '') {
      var Rcidade = '';
    } else { 
      var Rcidade = sessionStorage.getItem('cadastro_buscaCidade'); 
    }
      
    if (sessionStorage.getItem('cadastro_buscaRegional') == '') {
      var Rregional = '';
    } else { 
      var Rregional = sessionStorage.getItem('cadastro_buscaRegional'); 
    }
    
    if (id === null || id === undefined) {
      
    } else {
      var Rcodigo = id;
    }

    var data = {
      id: Rcodigo,
      dp_nome_rsociao: Rnome,
      de_nome_fantasia: Rfantasia,
      telefone: Rtelefone,
      tipo_pessoa: RtipoPessoa,
      tipo_socio: RtipoSocio,
      area_atuacao: RareaAtuacao,
      tipo_cadastro: RtipoCadastro,
      pais: Rpais,
      estado: Restado,
      cidade: Rcidade,
      regional: Rregional,
    };
           
    Api.post("cadastros/pesquisar", data).then(rps => {    
      setCadastros(rps.data.obj);
      if (id === null || id === undefined) {
      
      } else {
        editar(rps.data.obj[0]);
      }
      setCaProcessa(false);
    });
  }

  function getBuscaLetra($letra) {
    setCaProcessa(true);
    Api.get("cadastros/pesquisaLetra/" + $letra).then(rps => {
      setCadastros(rps.data.obj);
      setCaProcessa(false);
    });
  }

  function salvarCadastroEndereco() {
    setCaProcessa(true);
    formCadastroEndereco.id_cadastro = sessionStorage.getItem('idCadastro');
    Api.post('cadastrosendereco/adicionar', formCadastroEndereco).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModalEndereco(false);
        setFormCadastroEndereco({});
        getEnderecoCadastro(sessionStorage.getItem('idCadastro'));
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
      getListar();
    });
  }

  function editarEndereco(registro) {
    var reg = JSON.parse(JSON.stringify(registro));
    setAcao('Alterar');
    setFormCadastroEndereco(reg);
    setModalEndereco(true);
  }

  function excluirEndereco(endereco){
    var ca = JSON.parse(JSON.stringify(endereco));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('cadastrosendereco/excluir', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getEnderecoCadastro(sessionStorage.getItem('idCadastro'));
          } 
        })
      } 
      setCaProcessa(false);
    })
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Cadastro</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-md btn-success mt-6" onClick={e => {adicionar()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">          
            <div className="card card-custom">
              <div className="tab-content">
                <div className="tab-pane fade active show" id="kt_tab_pane_1_4" role="tabpanel" aria-labelledby="kt_tab_pane_1_4">
                  
                  {/*begin::Accordion*/}
    <div className="accordion accordion-solid accordion-toggle-plus" id="accordionExample6">
      <div className="card">
        <div className="card-header" id="headingOne6">
          <div className="card-title" data-toggle="collapse" data-target="#collapseOne6">
            <i className="flaticon-price-tag" />Busca por Cadastros</div>
        </div>
        <div id="collapseOne6" className="collapse show" data-parent="#accordionExample6">
          <div className="card-body">
            
            <div className="row">
              <div className="col-md-2 form-group">
                <input className="form-control" 
                   placeholder="Pesquisa por código"
                   value={buscaCodigo} 
                   onChange={e => {setBuscaCodigo(e.target.value)}} />
              </div>
              <div className="col-md-4 form-group">
                <input className="form-control" 
                   placeholder="Pesquisa por Nome / Razão Social"
                   value={buscaNome} 
                   onChange={e => {setBuscaNome(e.target.value)}} />
              </div>
              <div className="col-md-4 form-group">
                <input className="form-control" 
                   placeholder="Pesquisa por Empresa"
                   value={buscaFantasia} 
                   onChange={e => {setBuscaFantasia(e.target.value)}} />
              </div>
              <div className="col-md-2 form-group">
                <Input
                  className="form-control"
                  nacional={true}
                  country="BR"
                  maxLength="15"
                  placeholder='Pesquisa por Telefone'
                  value={buscaTelefone}
                  onChange={(e) => { setBuscaTelefone(e) }} 
                />
              </div>  
            </div>

            <div className="row">
              <div className="col-md-3 form-group">
                <Select 
                  options={tipoPessoaSelect}
                  placeholder="Selecione... Tipo de pessoa" 
                  defaultValue={tipoPessoaSelect.find(
                    x => x.value === buscaTipoPessoa
                  )}
                  onChange={(e) => { setBuscaTipoPessoa(e.value) }}
                />
              </div>
              <div className="col-md-3 form-group">
                <Select 
                   options={tipoSocioSelect}
                   placeholder="Selecione... Tipo de sócio" 
                   defaultValue={tipoSocioSelect.find(
                     x => x.value === buscaTipoSocio
                   )}
                   onChange={(e) => { setBuscaTipoSocio(e.value) }}
                   />
              </div>

              <div className="col-md-3 form-group">
                <Select 
                   options={areaAtuacaoSelect}
                   placeholder="Selecione... Área de atuação" 
                   defaultValue={areaAtuacaoSelect.find(
                     x => x.value === buscaAreaAtuacao
                   )}
                   onChange={(e) => { setBuscaAreaAtuacao(e.value) }}
                   />
              </div>

              <div className="col-md-3 form-group">
              <select className="form-control" value={buscaTipoCadastro} onChange={e => {setBuscaTipoCadastro(e.target.value)}}>
                <option value="" selected>Selecione... Tipo de cadastro</option>    
                <option value="Cadastro" >Cadastro</option>    
                <option value="Desativado" >Desativado</option>    
                <option value="Inativo" >Inativo</option>    
                <option value="Sócio" >Sócio</option>    
              </select>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 form-group">
                <Select 
                   options={paisSelect}
                   placeholder="Selecione... País" 
                   defaultValue={paisSelect.find(
                     x => x.value === buscaPais
                   )}
                   onChange={(e) => { setBuscaPais(e.value) }}
                   />
              </div>
              <div className="col-md-3 form-group">
                <Select 
                  options={estadoSelect}
                  placeholder="Selecione... Estado" 
                  defaultValue={estadoSelect.find(
                    x => x.value === buscaEstado
                  )}
                  onChange={(e) => { setBuscaEstado(e.value) }}
                />
              </div>

              <div className="col-md-3 form-group">
                <input
                   className="form-control" 
                   placeholder="Cidade"
                   value={buscaCidade} 
                   onChange={e => {setBuscaCidade(e.target.value)}} 
                   />
              </div>

              <div className="col-md-3 form-group">
                <Select 
                  options={regionalSelect}
                  placeholder="Selecione... Regional" 
                  defaultValue={regionalSelect.find(
                    x => x.value === buscaRegional
                  )}
                  onChange={(e) => { setBuscaRegional(e.value) }}
                   />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 text-right">
                <button className="btn btn-primary" onClick={e => buscar('Sim') }><i className="flaticon2-search-1" />Pesquisar</button>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12 text-center">
                <button className="btn btn-info" onClick={e => {getBuscaLetra("A")}}>A</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("B")}}>B</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("C")}}>C</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("D")}}>D</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("E")}}>E</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("F")}}>F</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("G")}}>G</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("H")}}>H</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("I")}}>I</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("J")}}>J</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("K")}}>K</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("L")}}>L</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("M")}}>M</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("N")}}>N</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("O")}}>O</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("P")}}>P</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("Q")}}>Q</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("R")}}>R</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("S")}}>S</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("T")}}>T</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("U")}}>U</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("V")}}>V</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("W")}}>W</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("X")}}>X</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("Y")}}>Y</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("Z")}}>Z</button>&nbsp;
                <button className="btn btn-secondary" onClick={e => {getListar()}}>Todos</button>
              </div>
            </div>  
          </div>
        </div>
      </div>
      </div>
           
           <br /><br />

                  <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          > 
                  <DataTable
                    title="Lista dos Cadastros"
                    columns={columns}
                    data={data}
                    striped
                    pagination="true"
                  />
                  </LoadingOverlay>

      
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>

        <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
          <Modal.Header>
          <Modal.Title>Manutenção no Cadastro</Modal.Title>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
                <i aria-hidden="true" className="ki ki-close" />
              </button>
          </Modal.Header>
          <div className="ml-10 mr-10 mt-2"> 
          <div className="row">
            <div className="col-md-12">             
              <div className="bg-light-success pt-2 pb-2 pl-2"><b>Cadastro</b></div>
            </div>
        </div>

        <br />    

			<div className="row">
				<div className="col-md-3">
          <label>Tipo de pessoa <b className="text-danger">*</b></label>
          <Select 
            options={tipoPessoaSelect}
            placeholder="Selecione... Tipo de pessoa" 
            defaultValue={tipoPessoaSelect.find(
              x => x.value === formCadastro.tipo_pessoa
            )}
            onChange={(e) => { setFormCadastro({...formCadastro, tipo_pessoa: e.value}) }}
          />
				</div>
				
				<div className="col-md-3">
          <label>Tipo de sócio <b className="text-danger">*</b></label>
          <Select 
            options={tipoSocioSelect}
            placeholder="Selecione... Tipo de sócio" 
            defaultValue={tipoSocioSelect.find(
              x => x.value === formCadastro.tipo_sociao
            )}
            onChange={(e) => { setFormCadastro({...formCadastro, tipo_sociao: e.value}) }}
          />
				</div>

        <div className="col-md-3">
          <label>Área de atuação <b className="text-danger">*</b></label>
          <Select 
            options={areaAtuacaoSelect}
            placeholder="Selecione... Área de atuação" 
            defaultValue={areaAtuacaoSelect.find(
              x => x.value === formCadastro.area_atuacao
            )}
            onChange={(e) => { setFormCadastro({...formCadastro, area_atuacao: e.value}) }}
          />
				</div>

        <div className="col-md-3">
          <label>Tipo de cadastro <b className="text-danger">*</b></label>
          <select className="form-control" value={formCadastro?.tipo_cadastro} 
                onChange={e => {setFormCadastro({...formCadastro, tipo_cadastro: e.target.value})}}>
            <option value="" selected>Selecione... Tipo de cadastro</option>    
            <option value="Cadastro" >Cadastro</option>    
            <option value="Desativado" >Desativado</option>    
            <option value="Inativo" >Inativo</option>    
            <option value="Sócio" >Sócio</option>    
          </select>
				</div>
			</div>

      <br />

      <div className="row">
        <div className="col-md-12">             
          <div className="bg-light-success pt-2 pb-2 pl-2"><b>
            {(formCadastro?.tipo_pessoa === '' || formCadastro?.tipo_pessoa === '1' || formCadastro?.tipo_pessoa === '5') && "Dados Pessoais"}
            {(formCadastro?.tipo_pessoa === '2' || formCadastro?.tipo_pessoa === '4') && "Dados da Empresa"}
            {(formCadastro?.tipo_pessoa === '3') && "Física Exterior"}
          </b></div>
        </div>
      </div>

      <br />

      <div className="row">
				<div className="col-md-9">
          <label>
            {(formCadastro?.tipo_pessoa === '' || formCadastro?.tipo_pessoa === '1' || formCadastro?.tipo_pessoa === '3' || formCadastro?.tipo_pessoa === '5') && "Nome"}
            {(formCadastro?.tipo_pessoa === '2' || formCadastro?.tipo_pessoa === '4') && "Razão Social"}
             &nbsp;<b className="text-danger">*</b></label>
          <input type="text" className="form-control" 
            value={formCadastro?.dp_nome_rsociao} 
            onChange={e => {setFormCadastro({...formCadastro, dp_nome_rsociao: e.target.value})}}/>
				</div>
			    
        {(formCadastro?.tipo_pessoa === '2' || formCadastro?.tipo_pessoa === '4') ? '' : 
          <div className="col-md-3" id="icnDataNascimento">
            <label>Nascimento</label>
                      <DatePicker 
                        name="dt_inicio"
                        onChange={(e) => { setFormCadastro({...formCadastro, dp_nasacimento: e}) }}
                        isClearable
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={formCadastro.dp_nasacimento ? formCadastro.dp_nasacimento : ''}
                        dateFormat="dd/MM/y"
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
          
				  </div>
        }
      </div>

      <div className="row">
				<div className="col-md-12">
          <label>Empresa</label>
          <input type="text" className="form-control" 
            value={formCadastro?.de_nome_fantasia} 
            onChange={e => {setFormCadastro({...formCadastro, de_nome_fantasia: e.target.value})}}
          />
				</div>
			</div>  

      <div className="row">
				<div className="col-md-4">
          <label>
            {(formCadastro?.tipo_pessoa === '' || formCadastro?.tipo_pessoa === '1' || formCadastro?.tipo_pessoa === '5') && "CPF"}
            {(formCadastro?.tipo_pessoa === '3' || formCadastro?.tipo_pessoa === '4') && "Passaporte"}
            {(formCadastro?.tipo_pessoa === '2') && "CNPJ"}  
          </label>
          <input type="text" className="form-control" 
            value={formCadastro?.dp_cpf_cnpj} 
            onChange={e => {setFormCadastro({...formCadastro, dp_cpf_cnpj: e.target.value})}}
          />
				</div>
			    
        {(formCadastro?.tipo_pessoa === '3' || formCadastro?.tipo_pessoa === '4') ? '' : 
          <div className="col-md-4">
          <label>
            {(formCadastro?.tipo_pessoa === '' || formCadastro?.tipo_pessoa === '1' || formCadastro?.tipo_pessoa === '5') && "RG"}
            {(formCadastro?.tipo_pessoa === '2') && "Inscrição Estadual"}
          </label>
          <input type="text" className="form-control" 
            value={formCadastro?.dp_rg_ie} 
            onChange={e => {setFormCadastro({...formCadastro, dp_rg_ie: e.target.value})}}
          />
          </div>
        }
				
        {(formCadastro?.tipo_pessoa === '2') && 
          <div className="col-md-4" id="icnIM">
            <label>Inscrição Municipal</label>
            <input type="text" className="form-control" 
              value={formCadastro?.de_insc_municipal} 
              onChange={e => {setFormCadastro({...formCadastro, de_insc_municipal: e.target.value})}}/>
          </div>
        }
                
        {(formCadastro?.tipo_pessoa === '2' || formCadastro?.tipo_pessoa === '4') ? '' : 
          <div className="col-md-4" id="icnDataCurso">
            <label>Data Conclusão Curso (DD/MM/AAAA)</label>
            
                        <DatePicker 
                        name="dt_inicio"
                        onChange={(e) => { setFormCadastro({...formCadastro, dp_conclusao_curso: e}) }}
                        isClearable
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected={formCadastro.dp_conclusao_curso}
                        dateFormat="dd/MM/y"
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
            
          </div>
        }
      </div>

      <br />

      <div className="row">
        <div className="col-md-12">             
          <div className="bg-light-success pt-2 pb-2 pl-2"><b>Contato</b></div>
        </div>
      </div>

      <br />

        <div className="row">
          <div className="col-md-2">
            <label>
              {(formCadastro?.tipo_pessoa === '1' || formCadastro?.tipo_pessoa === '3' || formCadastro?.tipo_pessoa === '5') && "Telefone(Residencial)"}
              {(formCadastro?.tipo_pessoa === '' || formCadastro?.tipo_pessoa === '2' || formCadastro?.tipo_pessoa === '4') && "Telefone 1"}
            </label>
            <Input
              className="form-control"
              nacional={true}
              country="BR"
              maxLength="15"
              value={formCadastro.telefone1}
              onChange={(e) => { setFormCadastro({...formCadastro, telefone1: e}) }} 
            /> 
          </div>
        {(formCadastro?.tipo_pessoa === '2' || formCadastro?.tipo_pessoa === '4') &&

          <div className="col-md-2" id="divRamal1">
            <label>Ramal 1</label>
            <input type="text" className="form-control" 
              value={formCadastro?.ramal1} 
              onChange={e => {setFormCadastro({...formCadastro, ramal1: e.target.value})}}
              /> 
          </div>
}
          <div className="col-md-2">
            <label>
              {(formCadastro?.tipo_pessoa === '1' || formCadastro?.tipo_pessoa === '3' || formCadastro?.tipo_pessoa === '5') && "Telefone(Comercial)"}
              {(formCadastro?.tipo_pessoa === '' || formCadastro?.tipo_pessoa === '2' || formCadastro?.tipo_pessoa === '4') && "Telefone 2"}
            </label>
            <Input
              className="form-control"
              nacional={true}
              country="BR"
              maxLength="15"
              value={formCadastro.telefone2}
              onChange={(e) => { setFormCadastro({...formCadastro, telefone2: e}) }} 
            />
          </div>
          <div className="col-md-2">
            <label>Ramal 2</label>
            <input type="text" className="form-control" 
              value={formCadastro?.ramal2} 
              onChange={e => {setFormCadastro({...formCadastro, ramal2: e.target.value})}}
              /> 
          </div>
          <div className="col-md-2">
            <label>
              {(formCadastro?.tipo_pessoa === '1' || formCadastro?.tipo_pessoa === '3' || formCadastro?.tipo_pessoa === '5') && "Outros"}
              {(formCadastro?.tipo_pessoa === '' || formCadastro?.tipo_pessoa === '2' || formCadastro?.tipo_pessoa === '4') && "Telefone 3"}
            </label>
            <Input
              className="form-control"
              nacional={true}
              country="BR"
              maxLength="15"
              value={formCadastro.telefone3}
              onChange={(e) => { setFormCadastro({...formCadastro, telefone3: e}) }} 
            /> 
          </div>
        {(formCadastro?.tipo_pessoa === '2' || formCadastro?.tipo_pessoa === '4') &&
          <div className="col-md-2" id="divRamal3">
            <label>
              {(formCadastro?.tipo_pessoa === '1' || formCadastro?.tipo_pessoa === '3' || formCadastro?.tipo_pessoa === '5') && "Ramal(Comercial)"}
              {(formCadastro?.tipo_pessoa === '' || formCadastro?.tipo_pessoa === '2' || formCadastro?.tipo_pessoa === '4') && "Ramal 2"}
            </label>
            <input type="text" className="form-control" 
              value={formCadastro?.ramal3} 
              onChange={e => {setFormCadastro({...formCadastro, ramal3: e.target.value})}}
              /> 
          </div>
        }
          <div className="col-md-2">
            <label>
              {(formCadastro?.tipo_pessoa === '1' || formCadastro?.tipo_pessoa === '3' || formCadastro?.tipo_pessoa === '5') && "Celular(1)"}
              {(formCadastro?.tipo_pessoa === '' || formCadastro?.tipo_pessoa === '2' || formCadastro?.tipo_pessoa === '4') && "Telefone 4"}
            </label>
            <Input
              className="form-control"
              nacional={true}
              country="BR"
              maxLength="15"
              value={formCadastro.celular1}
              onChange={(e) => { setFormCadastro({...formCadastro, celular1: e}) }} 
            /> 
          </div>
          <div className="col-md-2">
            <label>
              {(formCadastro?.tipo_pessoa === '1' || formCadastro?.tipo_pessoa === '3' || formCadastro?.tipo_pessoa === '5') && "Celular(2)"}
              {(formCadastro?.tipo_pessoa === '' || formCadastro?.tipo_pessoa === '2' || formCadastro?.tipo_pessoa === '4') && "Telefone 5"}
            </label>
            <Input
              className="form-control"
              nacional={true}
              country="BR"
              maxLength="15"
              value={formCadastro.celular2}
              onChange={(e) => { setFormCadastro({...formCadastro, celular2: e}) }} 
            /> 
          </div>
        {(formCadastro?.tipo_pessoa === '2' || formCadastro?.tipo_pessoa === '4') &&
          <div className="col-md-2" id="divFax">
            <label>FAX</label>
            <Input
              className="form-control"
              nacional={true}
              country="BR"
              maxLength="15"
              value={formCadastro.fax}
              onChange={(e) => { setFormCadastro({...formCadastro, fax: e}) }} 
            />  
          </div>
        }
        </div>
        <div className="row">
          <div className="col-md-6">
            <label>Email</label>
            <input type="text" className="form-control" 
              value={formCadastro?.email} 
              onChange={e => {setFormCadastro({...formCadastro, email: e.target.value})}}
              /> 
          </div>
          <div className="col-md-6">
            <label>Email 2</label>
            <input type="text" className="form-control" 
              value={formCadastro?.email2} 
              onChange={e => {setFormCadastro({...formCadastro, email2: e.target.value})}}
              /> 
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label>Email 3</label>
            <input type="text" className="form-control" 
              value={formCadastro?.email3} 
              onChange={e => {setFormCadastro({...formCadastro, email3: e.target.value})}}
              /> 
          </div>
          <div className="col-md-6">
            <label>Email 4</label>
            <input type="text" className="form-control" 
              value={formCadastro?.email4} 
              onChange={e => {setFormCadastro({...formCadastro, email4: e.target.value})}}
              /> 
          </div>
        </div>
      
      <br /> 
      { formCadastro.id != null && <>
      <div className="row">
        <div className="col-md-12" align="right">
          <button className="btn btn-primary btn-sm"
              onClick={e => { 
              setModalEndereco(true);
              setAcao('Adicionar'); 
              setFormCadastroEndereco([]);
              sessionStorage.setItem('idCadastro', formCadastro.id);}}><i className="fas fa-plus" />Adicionar Endereço
          </button>
        </div>
      </div>
      </>
      }       
      
      <div className="row">
        <div className="col-md-12">             
          <div className="bg-light-success pt-2 pb-2 pl-2"><b>Endereço</b></div>
        </div>
      </div>
     
      { formCadastro.id != null && <>
      <table class="table table-striped">
        <tr>
          <th>Tipo</th>
          <th>Rev</th>
          <th>Nome</th>
          <th>Numero</th>
          <th>Cidade</th>
          <th>Bairro</th>
          <th>Estado</th>
          <th>Cep</th>
          <th width="18%" class="text-center">Ações</th>
        </tr>
        <tr>
          <th colspan="3">Departamento</th>
          <th colspan="3">Complemento</th>
          <th>Pais</th>
          <th colspan="2">Caixa Postal</th>
        </tr>
				  { cadastrosEndereco.map ( item => {
            return (
              <>
            	  <tr className={ item.principal == "S" ? "bg-light-success" : "" }>
                  <td>{item.tipo}</td>
                  <td>{item.revista}</td>
                  <td>{item.endereco}</td>
                  <td>{item.numero}</td>
                  <td>{item.cidade}</td>
                  <td>{item.bairro}</td>
                  <td>{item.uf}</td>
                  <td>{item.cep}</td>
               		<td class="text-center">
                    <button className="btn btn-warning btn-sm mr-2" onClick={e => { editarEndereco(item) }} ><i className="fas fa-pencil-alt" />Alterar</button>
				    	      <button className="btn btn-danger btn-sm" onClick={e => { excluirEndereco(item) }}><i className="far fa-trash-alt" />Excluir</button>                  
					        </td>
            	  </tr>
                <tr className={ item.principal == "S" ? "bg-light-success" : "" }>
                  <td colspan="3">{item.departamento}</td>
                  <td colspan="3">{item.complemento}</td>
                  <td>{item.pais}</td>
                  <td colspan="2">{item.caixa_postal}</td>
                </tr>
                </>
                )
              })}
				      </table>
            </>
          }

      { formCadastro.id == null && <>
      <div className="row">
        <div className="col-md-12">
        <div class="card card-custom bg-light-info">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <h4 class="text-dark mb-5">Informação</h4>
                <p class="text-dark-75">O botão para Adicionar Endereço irá ficar disponível após clicar no botão Salvar</p>
              </div>
              
            </div>
          </div>
        </div>
				</div>
      </div>
      </>
      }
 
      <br />

      <div className="row">
        <div className="col-md-12">             
          <div className="bg-light-success pt-2 pb-2 pl-2"><b>Observações</b></div>
        </div>
      </div>

     <br />

      <div className="row">
				<div className="col-md-10">
          <textarea className="form-control" value={formCadastro?.observacoes} 
            onChange={e => {setFormCadastro({...formCadastro, observacoes: e.target.value})}}></textarea>
				</div>
			    
        <div className="col-md-2">
          <label>Nome crachá</label>
          <input type="text" className="form-control" 
            value={formCadastro?.nome_cracha} 
            onChange={e => {setFormCadastro({...formCadastro, nome_cracha: e.target.value})}}
            /> 
				</div>
      </div>

			</div>
          <Modal.Footer>
            <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
            <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
          </Modal.Footer>
        </Modal>



      <Modal size={'md'} centered show={modalEndereco} onHide={() => fecharModalEndereco()}>
      <Modal.Header>
      <Modal.Title>{acao} Endereço</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModalEndereco()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header> 

      <div className="row ml-3 mr-3">
        <div className="form-group col-md-2">
          <label>Tipo <b className="text-danger">*</b></label>
          <select className="form-control" value={formCadastroEndereco?.tipo} 
                onChange={e => {setFormCadastroEndereco({...formCadastroEndereco, tipo: e.target.value})}}>
            <option value="" selected>Selecione... </option>    
            <option value="Comercial" >Comercial</option>    
            <option value="Residencial" >Residencial</option>        
          </select>
        </div>

        <div className="form-group col-md-2">
          <label>Principal <b className="text-danger">*</b></label>
          <select className="form-control" value={formCadastroEndereco?.principal} 
                onChange={e => {setFormCadastroEndereco({...formCadastroEndereco, principal: e.target.value})}}>
            <option value="" selected>Selecione... </option>    
            <option value="N">Não</option>    
            <option value="S">Sim</option>        
          </select>
        </div>

        <div className="form-group col-md-3">
          <label>Revista</label>
          <select className="form-control" value={formCadastroEndereco?.revista} 
                onChange={e => {setFormCadastroEndereco({...formCadastroEndereco, revista: e.target.value})}}>
            <option value="" selected>Selecione... </option>    
            <option value="SR">Sem Revista</option>
            <option value="R1">Revista 1</option>    
            <option value="R2">Revista 2</option>        
            <option value="R3">Revista 3</option>
            <option value="R4">Revista 4</option>        
            <option value="R5">Revista 5</option>        
          </select>
        </div>

        <div className="form-group col-md-2">
          <label>Caixa Postal</label>
          <input className="form-control"
            placeholder="Caixa Postal" 
            value={formCadastroEndereco?.caixa_postal} 
            onChange={(e) => { setFormCadastroEndereco({...formCadastroEndereco, caixa_postal: e.target.value}) }}/>  
        </div>

        <div className="form-group col-md-3">
          <label>CEP</label>
          <input className="form-control"
            placeholder="CEP" 
            value={formCadastroEndereco?.cep} 
            onChange={(e) => { setFormCadastroEndereco({...formCadastroEndereco, cep: e.target.value}) }}/>  
        </div>
      </div>

      <div className="row ml-3 mr-3">
        <div className="form-group col-md-10">
          <label>Endereço <b className="text-danger">*</b></label>
          <input className="form-control"
            placeholder="Endereço" 
            value={formCadastroEndereco?.endereco} 
            onChange={(e) => { setFormCadastroEndereco({...formCadastroEndereco, endereco: e.target.value}) }}/>  
        </div>

        <div className="form-group col-md-2">
          <label>Número <b className="text-danger">*</b></label>
          <input className="form-control"
            placeholder="Número" 
            value={formCadastroEndereco?.numero} 
            onChange={(e) => { setFormCadastroEndereco({...formCadastroEndereco, numero: e.target.value}) }}/>  
        </div>
      </div>

      <div className="row ml-3 mr-3">
        <div className="form-group col-md-12">
          <label>Complemento</label>
          <input className="form-control"
            placeholder="Complemento" 
            value={formCadastroEndereco?.complemento} 
            onChange={(e) => { setFormCadastroEndereco({...formCadastroEndereco, complemento: e.target.value}) }}/>  
        </div>
      </div>

      <div className="row ml-3 mr-3">
        <div className="form-group col-md-3">
          <label>País <b className="text-danger">*</b></label>
          <Select 
            options={paisSelect}
            placeholder="Selecione... País" 
            defaultValue={paisSelect.find(
              x => x.value === formCadastroEndereco.pais
            )}
            onChange={(e) => { setFormCadastroEndereco({...formCadastroEndereco, pais: e.value}) }}
            />  
        </div>

        <div className="form-group col-md-3">
          <label>Estado </label>
          <LoadingOverlay
            active={caProcessaEstado}
            spinner
            text='Carregando...'
          >
          <Select 
            options={estadoSelect}
            placeholder="Selecione... Estado" 
            onChange={(e) => { setFormCadastroEndereco({...formCadastroEndereco, uf: e.value}) }}
            value={estadoSelect.find(
              x => x.value === formCadastroEndereco.uf
            )}
          />  
          </LoadingOverlay> 
        </div>

        <div className="form-group col-md-3">
          <label>Cidade <b className="text-danger">*</b></label>
          <LoadingOverlay
            active={caProcessaCidade}
            spinner
            text='Carregando...'
          >
          <input className="form-control"
            placeholder="Cidade" 
            value={formCadastroEndereco?.cidade} 
            onChange={(e) => { setFormCadastroEndereco({...formCadastroEndereco, cidade: e.target.value}) }}/>
          </LoadingOverlay>
        </div>

        <div className="form-group col-md-3">
          <label>Bairro</label>
          <input className="form-control"
            placeholder="Bairro" 
            value={formCadastroEndereco?.bairro} 
            onChange={(e) => { setFormCadastroEndereco({...formCadastroEndereco, bairro: e.target.value}) }}/>  
        </div>
      </div>

      <div className="row ml-3 mr-3">
        <div className="form-group col-md-12">
          <label>Departamento</label>
          <input className="form-control"
            placeholder="Departamento" 
            value={formCadastroEndereco?.departamento} 
            onChange={(e) => { setFormCadastroEndereco({...formCadastroEndereco, departamento: e.target.value}) }}/>  
        </div>
      </div>

      <Modal.Footer>
        <button type="button" onClick={e => { fecharModalEndereco(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvarCadastroEndereco(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>  
        </>
    );

}

export default withRouter(Cadastro);                  