import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";


import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const UsuarioSenha = () => {
  const { addToast } = useToasts();
  const refArtigo = React.useRef(null);
  const [ usuarios, setUsuarios ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ formUsuario, setFormUsuario ] = useState({});

   useEffect(() => {
    filtrar();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    Api.get("usuario/listar").then(rps => {
      
      setUsuarios(rps.data.obj[0]);

      setCaProcessa(false);
    })
  }
  


  function salvar(){
    setCaProcessa(true);
    
    Api.post('usuario/adicionar', formUsuario).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        id: localStorage.getItem('id_usuario'),
      };
    
      Api.post('usuario/filtrar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setFormUsuario(rps.data.obj[0]);
        }
      setCaProcessa(false); 
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-6 mb-2 mr-5">Alterar Senha de Usuário</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-6 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">

                                


                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
              <div className="row mt-5 ml-5 mr-5">
                <div className="form-group col-md-8">
                  <label>Nome</label>
                  <input disabled type="text" value={formUsuario?.name} onChange={e => {setFormUsuario({...formUsuario, name: e.target.value})}} className="form-control" />
                </div>

                <div className="form-group col-md-2">
                  <label>Tipo</label>
                  <select disabled className="form-control" value={formUsuario?.tipo} onChange={e => {setFormUsuario({...formUsuario, tipo: e.target.value})}}>
                    <option value="" selected>Selecione</option>    
                    <option value="Administrador">Administrador</option>    
                    <option value="Usuário">Usuário</option>    
                  </select>
                </div>

                <div className="form-group col-md-2">
                  <label>Status</label>
                  <select disabled className="form-control" value={formUsuario?.status} onChange={e => {setFormUsuario({...formUsuario, status: e.target.value})}}>
                    <option value="" selected>Selecione</option>    
                    <option value="S">Ativo</option>    
                    <option value="N">Desativado</option>    
                  </select>
                </div>
              </div>
          
              <div className="row ml-5 mr-5">
                <div className="form-group col-md-12">
                  <label>E-mail</label>
                  <input disabled type="text" value={formUsuario?.email} onChange={e => {setFormUsuario({...formUsuario, email: e.target.value})}} className="form-control" />
                </div>
              </div>

              <div className="row ml-5 mr-5">
                <div className="form-group col-md-6">
                  <label>Senha</label>
                  <input type="password" onChange={e => {setFormUsuario({...formUsuario, senha: e.target.value})}} className="form-control" />
                </div>

                <div className="form-group col-md-6">
                  <label>Confirmação de Senha</label>
                  <input type="password" onChange={e => {setFormUsuario({...formUsuario, senha2: e.target.value})}} className="form-control" />
                </div>
              </div>

        
              <div className="row ml-5 mr-5 mb-5">
                <div className="text-right col-md-12">
                   <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
                </div>
              </div>

              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      
                 
        </>
    );
}

export default withRouter(UsuarioSenha);                  